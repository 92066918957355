import React from "react";
import Layout from "../../components/Layout/Layout";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import LegalNav from "../../components/LegalNav/LegalNav";

const Content = () => {
  return (
    <>
      <p>
        T Cards Direct has developed the T Cards Online system plus certain
        software applications and platforms which it makes available to
        subscribers via the internet on a pay-per-use basis for the purpose of
        utilising the T Cards Online Service. The Customer wishes to use T Cards
        Online Service in its business operations. T Cards Direct has agreed to
        provide and the Customer has agreed to take and pay for the T Cards
        Online Service subject to these terms and conditions (
        <b>“these terms”</b>).
      </p>
      <p>
        <b>Agreed terms</b>
      </p>
      <p>
        <b>1. Interpretation</b>
      </p>
      <p>
        1.1 The definitions and rules of interpretation in this clause apply in
        these terms and
      </p>
      <p>
        <b>Anniversary:</b> means the date of renewal of the contract between T
        Cards Direct and the Customer.
      </p>
      <p>
        <b>Applicable Laws:</b> means:
        <br />
        (a) To the extent the UK GDPR applies, the law of the United Kingdom or
        of a part of the United Kingdom.
        <br />
        (b) To the extent EU GDPR applies, the law of the European Union or any
        member state of the European Union to which T Cards Direct is subject.
      </p>
      <p>
        <b>Applicable Data Protection Laws:</b> means:
        <br />
        (a) To the extent the UK GDPR applies, the law of the United Kingdom or
        of a part of the United Kingdom which relates to the protection of
        personal data.
        <br />
        (b) To the extent the EU GDPR applies, the law of the European Union or
        any member state of the European Union to which T Cards Direct is
        subject, which relates to the protection of personal data.
      </p>
      <p>
        <b>Authorised Users:</b> those employees, agents and independent
        contractors of the Customer who are authorised by the Customer to use
        the Services and the Documentation, as further described in 2.4(d)
      </p>
      <p>
        <b>Business Day:</b> a day other than a Saturday, Sunday or public
        holiday in England when banks in London are open for business.
      </p>
      <p>
        <b>Change of Control:</b> [shall be as defined in section 1124 of the
        Corporation Tax Act 2010, and the expression <b>change of control</b>{" "}
        shall be construed accordingly <b>OR</b> the beneficial ownership of
        more than 50% of the issued share capital of a company or the legal
        power to direct or cause the direction of the general management of the
        company, and <b>controls, controlled</b> and the expression{" "}
        <b>change of control</b> shall be construed accordingly.]
      </p>
      <p>
        <b>Confidential Information:</b> information that is proprietary or
        confidential and is either clearly labelled as such or identified as
        Confidential Information in clause 11.5 or clause 11.6.
      </p>
      <p>
        <b>Customer Data:</b> the data inputted by the Customer, Authorised
        Users, or T Cards Direct on the Customer's behalf for the purpose of
        using the Services or facilitating the Customer's use of the Services.
      </p>
      <p>
        <b>Customer Personal Data:</b> any personal data which T Cards Direct
        processes in connection with these terms, in the capacity of a processor
        on behalf of the Customer.
      </p>
      <p>
        <b>Documentation:</b> the documents made available to the Customer by T
        Cards Direct online via the website or such other web address notified
        by T Cards Direct to the Customer from time to time which sets out a
        description of the Services and the user instructions for the Services.
      </p>
      <p>
        <b>Effective Date:</b> the date of these terms.
      </p>
      <p>
        <b>EU GDPR:</b> the General Data Protection Regulation ((EU) 2016/679).
      </p>
      <p>
        <b>Excess Data Storage Fees:</b> the fees for storage of Customer’s data
        in excess of 1 gigabyte.
      </p>
      <p>
        <b>Manage Account Page:</b> means the Manage Account page on the Website
        to be used by the Customer to give notice to T Cards Direct of changes
        in User Subscriptions
      </p>
      <p>
        <b>Normal Business Hours:</b> 9.00 am to 5.00 pm local UK time, each
        Business Day.
      </p>
      <p>
        <b>Purpose:</b> the purposes for which the Customer data and Personal
        Data is processed.
      </p>
      <p>
        <b>Registration page:</b> means the registration page on the Website to
        be completed by the Customer for registering with T Cards Direct and
        subscribing for the provision of the Services under these terms;
      </p>
      <p>
        <b>Services:</b> the subscription services provided by T Cards Direct to
        the Customer under these terms via{" "}
        <a href='https://www.tcardsonline.co.uk'>www.tcardsonline.co.uk</a> or
        any other website notified to the Customer by T Cards Direct from time
        to time, as more particularly described in the Documentation.
      </p>
      <p>
        <b>Software:</b> the online software applications provided by T Cards
        Direct as part of the Services.
      </p>
      <p>
        <b>Subscription Fees:</b> the subscription fees payable by the Customer
        to T Cards Direct for the User Subscriptions.
      </p>
      <p>
        <b>Subscription Period:</b> means the period of time during which the
        Customer subscribes to the Services.
      </p>
      <p>
        <b>Subscription Term:</b> has the meaning given in clause 14.1
      </p>
      <p>
        <p>T Cards Direct:</p> means the partnership called T Cards Direct,
        consisting of partners: M Heine, P D Heine and S J Richetta, whose
        operating address is at Lillian House, Clearway, West Malling, Kent ME19
        5BP.
      </p>
      <p>
        <b>T Cards Direct Personal Data:</b> any personal data which T Cards
        Direct processes in connection with these terms, in the capacity of a
        controller.
      </p>
      <p>
        <b>Support Services Policy:</b> T Cards Direct's policy for providing
        support in relation to the Services as made available at
        <a href='https://www.tcardsonline.co.uk/legal/serviceagreement'>
          https://www.tcardsonline.co.uk/legal/serviceagreement
        </a>{" "}
        or such other website address as may be notified to the Customer from
        time to time.
      </p>
      <p>
        <b>User Subscriptions:</b> the user subscriptions purchased by the
        Customer pursuant to clause 9.1 which entitle Authorised Users to access
        and use the Services and the Documentation in accordance with these
        terms.
      </p>
      <p>
        <b>UK GDPR:</b> has the meaning given to it in the Data Protection Act
        2018.
      </p>
      <p>
        <b>Virus:</b> any thing or device (including any software, code, file or
        programme) which may: prevent, impair or otherwise adversely affect the
        operation of any computer software, hardware or network, any
        telecommunications service, equipment or network or any other service or
        device; prevent, impair or otherwise adversely affect access to or the
        operation of any programme or data, including the reliability of any
        programme or data (whether by re-arranging, altering or erasing the
        programme or data in whole or part or otherwise); or adversely affect
        the user experience, including worms, trojan horses, viruses and other
        similar things or devices.
      </p>
      <p>
        <b>Website:</b> the web application and associated services operated by
        T Cards Direct, for the provision of the Web T Card Service, under the
        domain tcardsonline. or any other url address notified to the Customer
        by T Cards Direct from time to time during the Subscription Term.
      </p>
      <p>
        1.2 Clause, schedule and paragraph headings shall not affect the
        interpretation of these terms.
      </p>
      <p>
        1.3 A person includes an individual, corporate or unincorporated body
        (whether or not having separate legal personality) [and that person's
        legal and personal representatives, successors or permitted assigns].
      </p>
      <p>
        1.4 A reference to a company shall include any company, corporation or
        other body corporate, wherever and however incorporated or established.
      </p>
      <p>
        1.5 Unless the context otherwise requires, words in the singular shall
        include the plural and in the plural shall include the singular.
      </p>
      <p>
        1.6 Unless the context otherwise requires, a reference to one gender
        shall include a reference to the other genders.
      </p>
      <p>
        1.7 A reference to a statute or statutory provision is a reference to it
        as it is in force as at the date of these terms.
      </p>
      <p>
        1.8 A reference to a statute or statutory provision shall include all
        subordinate legislation made as at the date of these terms under that
        statute or statutory provision.
      </p>
      <p>
        1.9 A reference to writing or written includes faxes but not e-mail.
      </p>
      <p>
        1.10 References to clauses and schedules are to the clauses and
        schedules of these terms; references to paragraphs are to paragraphs of
        the relevant schedule to these terms.
      </p>
      <p>
        <b>User Subscriptions</b>
      </p>
      <p>
        2.1 The Customer shall subscribe for provision of the Services by
        completing the Registration Page on the Website. The Customer’s
        submission of the Registration Page on the Website to T Cards Direct is
        the Customer’s offer to be provided with the Services. T Cards Direct
        reserves the right to accept or reject an offer from the Customer for
        any reason. If T Cards Direct wishes to accept the Customer’s offer,
        then T Cards Direct shall issue a Subscription Confirmation email to the
        Customer, at which time a contract shall come into effect between T
        Cards Direct and the Customer (<b>“Effective Date”</b>) and the Customer
        shall be entitled to access the Services via the Website using the
        passwords and any other login details provided by T Cards Direct to the
        Customer in the Subscription Confirmation.
      </p>
      <p>
        2.2 At the point of registration, the Customer shall be able to use T
        Cards Online for a trial period of up to 21 days free of charge. The
        Customer can then purchase the Subscriptions at any time during the
        trial period and the Subscription Period will then run for a calendar
        month and continue on a rolling basis.
      </p>
      <p>
        2.3 Subject to the Customer purchasing the User Subscriptions in
        accordance with Clause 3.3 and Clause 9.1, the restrictions set out in
        this Clause 2 and the other terms and conditions of these terms, T Cards
        Direct hereby grants to the Customer (from the Effective Date) a
        non-exclusive, non-transferable right to permit the Authorised Users to
        use the Services and the Documentation during the Subscription Term
        solely for the Customer’s internal business operations.
      </p>
      <p>
        2.4 In relation to the Authorised Users, the Customer undertakes that:
        <br />
        (a) the maximum number of Authorised Users that it authorises to access
        and use the Services and the Documentation shall not exceed the number
        of User Subscriptions it has purchased from time to time;
        <br />
        (b) it will not allow or suffer any User Subscription to be used by more
        than one individual Authorised User unless it has been reassigned in its
        entirety to another individual Authorised User, in which case the prior
        Authorised User shall no longer have any right to access or use the
        Services and/or Documentation;
        <br />
        (c) each Authorised User shall keep a secure password for his use of the
        Services and Documentation, that such password shall be changed no less
        frequently than monthly and that each Authorised User shall keep his
        password confidential;
        <br />
        (d) it shall maintain a written, up to date list of current Authorised
        Users and provide such list to T Cards Direct within five (5) Business
        Days of T Cards Direct’s written request at any time or times;
        <br />
        (e) it shall permit T Cards Direct to audit the Services in order to
        establish the name and password of each Authorised User. Such audit may
        be conducted no more than once per quarter, at T Cards Direct’s expense,
        and this right shall be exercised with reasonable prior notice, in such
        a manner as not to substantially interfere with the Customer’s normal
        conduct of business;
        <br />
        (f) if any of the audits referred to in Clause 2.3.5 reveal that any
        password has been provided to any individual who is not an Authorised
        User, then without prejudice to T Cards Direct’s other rights, the
        Customer shall promptly disable such passwords and T Cards Direct shall
        not issue any new passwords to any such individual; and
        <br />
        (g) if any of the audits referred to in Clause 2.3.5 reveal that the
        Customer has underpaid Subscription Fees to T Cards Direct, the Customer
        shall pay to T Cards Direct an amount equal to such underpayment as
        calculated in accordance with the prices set out on the Website within
        ten (10) Business Days of the date of the relevant audit.
      </p>
      <p>
        2.5 The Customer shall not access, store, distribute or transmit any
        Viruses, or any material during the course of its use of the Services
        that:
        <br />
        (a) is unlawful, harmful, threatening, defamatory, obscene, infringing,
        harassing or racially or ethnically offensive;
        <br />
        (b) facilitates illegal activity;
        <br />
        (c) depicts sexually explicit images;
        <br />
        (d) promotes unlawful violence;
        <br />
        (e) is discriminatory based on race, gender, colour, religious belief,
        sexual orientation, disability, or any other illegal activity; or
        <br />
        (f) causes damage or injury to any person or property; and T Cards
        Direct reserves the right, without liability to the Customer, to disable
        the Customer’s access to any material that breaches the provisions of
        this clause.
      </p>
      <p>
        2.6 The Customer shall not:
        <br />
        (a) except as may be allowed by any applicable law which is incapable of
        exclusion by agreement between the parties: (i) and except to the extent
        expressly permitted under these terms, attempt to copy, modify,
        duplicate, create derivative works from, frame, mirror, republish,
        download, display, transmit, or distribute all or any portion of the
        Software and/or Documentation (as applicable) in any form or media or by
        any means; or (ii) attempt to reverse compile, disassemble, reverse
        engineer or otherwise reduce to human-perceivable form all or any part
        of the Software; or
        <br />
        (b) access all or any part of the Services and Documentation in order to
        build a product or service which competes with the Services and/or the
        Documentation; or
        <br />
        (c) use the Services and/or Documentation to provide services to third
        parties; or
        <br />
        (d) subject to Clause 19.1, license, sell, rent, lease, transfer,
        assign, distribute, display, disclose, or otherwise commercially
        exploit, or otherwise make the Services and/or Documentation available
        to any third party except the Authorised Users, or
        <br />
        (e) attempt to obtain, or assist third parties in obtaining, access to
        the Services and/or Documentation, other than as provided under this
        Clause 2.
      </p>
      <p>
        2.7 The Customer shall use all reasonable endeavours to prevent any
        unauthorised access to, or use of, the Services and/or the Documentation
        and, in the event of any such unauthorised access or use, promptly
        notify T Cards Direct.
      </p>
      <p>
        2.8 The rights provided under this Clause 2 are granted to the Customer
        only and shall not be considered granted to any subsidiary or holding
        company of the Customer.
      </p>
      <p>
        <b>3. Additional Users Subscriptions</b>
      </p>
      <p>
        3.1 Subject to Clauses 3.2 and 3.3, the Customer may, from time to time
        during any Subscription Term, purchase additional User Subscriptions in
        excess of the number requested on the Registration Page (or as added to
        during the Subscription Term by agreement between the parties) and T
        Cards Direct shall grant access to the Services and the Documentation to
        such additional Authorised Users in accordance with the provisions of
        these terms.
      </p>
      <p>
        3.2 If the Customer wishes to purchase additional User Subscriptions
        then the Customer shall give notice to T Cards Direct:
        <br />
        (a) if the Customer pays using Stripe , by submitting a request on the
        Management Account Page. T Cards shall confirm the request by updating
        the customer Dashboard.
        <br />
        (b) if the Customer pays by invoice or Worldpay, by submitting a written
        request to support@tcardsonline.com. T Cards Direct shall respond by
        sending confirmation to the customer by email.
      </p>
      <p>
        3.3 The Customer shall pay to T Cards Direct the relevant fees for such
        additional User Subscriptions as set out on the Website and, if such
        additional User Subscriptions are purchased by the Customer part way
        through a calendar month, then the monthly Subscription Fee shall be
        charged and collected on a pro-rata basis for the remainder of that
        month.
      </p>
      <p>
        3.4 If the Customer wishes to reduce the User Subscriptions, then the
        Customer shall give notice to T Cards Direct:
        <br />
        (a) if the Customer pays using Stripe, by submitting a request on the
        Management Account Page. T Cards shall confirm the request by updating
        the customer Dashboard.
        <br />
        (b) if the Customer pays by invoice or Worldpay, by submitting a written
        request to support@tcardsonline.com. T Cards Direct shall respond by
        sending confirmation to the customer by email.
        <br />T Cards Direct shall make the appropriate change to the monthly
        Subscription Fee for the next and subsequent months until the Customers
        requests a further change or terminates the Subscription in accordance
        with clause 14.1. If notice of removal of an Authorised User and
        therefore reduction in the User Subscriptions occurs part way through a
        month then the Customer shall not be entitled to any refund of the
        Subscription Fee relevant to the reduced number of the User
        Subscriptions.
      </p>
      <p>
        <b>4. Services</b>
      </p>
      <p>
        4.1 T Cards Direct shall, during the Subscription Term, provide the
        Services and make available the Documentation to the Customer on and
        subject to the terms of these terms.
      </p>
      <p>
        4.2 T Cards Direct shall use commercially reasonable endeavours to make
        the Services available at all times, except for:
        <br />
        (a) planned maintenance carried out and notified to the Customer; and
        <br />
        (b) unscheduled maintenance required to be performed to ensure security
        of the Website, as notified to the Customer at the earliest opportunity.
      </p>
      <p>
        4.3 T Cards Direct will, as part of the Services and at no additional
        cost to the Customer, provide the Customer with T Cards Direct’s
        standard customer support services during Normal Business Hours in
        accordance with T Cards Direct’s Support Services Policy in effect at
        the time that the Services are provided. T Cards Direct may amend the
        Support Services Policy in its sole and absolute discretion from time to
        time. The Customer may purchase enhanced support services separately at
        T Cards Direct’s then current rates.
      </p>
      <p>
        <b>5. Data protection</b>
      </p>
      <p>
        5.1 For the purposes of this clause 5, the terms controller, processor,
        data subject, personal data, personal data breach and processing shall
        have the meaning given to them in the UK GDPR.
      </p>
      <p>
        5.2 Both parties will comply with all applicable requirements of
        Applicable Data Protection Laws. This clause 5 is in addition to, and
        does not relieve, remove or replace, a party's obligations or rights
        under Applicable Data Protection Laws.
      </p>
      <p>
        5.3 The parties have determined that, for the purposes of Applicable
        Data Protection Laws:
        <br />
        (a) The Customer shall act as controller of the personal data as set out
        in its Privacy Policy which can be viewed{" "}
        <a href='https://www.tcardsonline.co.uk/legal/privacy'>here</a>; and
        <br />
        (b) T Cards Direct shall process the personal data as set out in
        accordance with its Privacy Policy which can be viewed{" "}
        <a href='https://www.tcardsonline.co.uk/legal/privacy'>here</a>, as a
        processor on behalf of the Customer.
      </p>
      <p>
        5.4 Should the determination in clause 5.3 change, then each party shall
        work together in good faith to make any changes which are necessary to
        this clause 1 or the related schedules.
      </p>
      <p>
        5.5 By entering into these terms, the Customer consents to (and shall
        procure all required consents, from its personnel, representatives and
        agents, in respect of) all actions taken by T Cards Direct in connection
        with the processing of the Customer’s Personal Data, provided these are
        in compliance with the then-current version of T Cards Direct's privacy
        policy available{" "}
        <a href='https://www.tcardsonline.co.uk/legal/privacy'>here.</a> In the
        event of any inconsistency or conflict between the terms of the Privacy
        Policy and these terms, the Privacy Policy will take precedence.
      </p>
      <p>
        5.6 Without prejudice to the generality of clause 5.2, the Customer will
        ensure that it has all necessary appropriate consents and notices in
        place to enable lawful collection of Customer Personal Data to T Cards
        Direct for the duration and purposes of these terms.
      </p>
      <p>
        5.7 In relation to the Customer Personal Data, T Card’s Direct’s{" "}
        <a href='https://www.tcardsonline.co.uk/legal/privacy'>
          Privacy Policy
        </a>{" "}
        sets out the scope, nature and purpose of processing by T Cards Direct,
        the duration of the processing and the types of personal data and
        categories of data subject.
      </p>
      <p>
        5.8 Without prejudice to the generality of clause 5.2 T Cards Direct
        shall, in relation to Customer Personal Data:
        <br />
        (a) process that Customer Personal Data only on the documented
        instructions of the Customer, which shall be to process the Customer
        Personal Data for the purposes as set out in T Cards Direct’s Privacy
        Policy, unless T Cards Direct is required by Applicable Laws to
        otherwise process that Customer Personal Data. Where T Cards Direct is
        relying on Applicable Laws as the basis for processing Customer
        Processor Data, T Cards Direct shall notify the Customer of this before
        performing the processing required by the Applicable Laws unless those
        Applicable Laws prohibit the Provider from so notifying the Customer on
        important grounds of public interest. T Cards Direct shall inform the
        Customer if, in the opinion of T Cards Direct, the instructions of the
        Customer infringe Applicable Data Protection Legislation;
        <br />
        (b) implement the technical and organisational measures as set out in T
        Cards Direct’s Privacy Policy to protect against unauthorised or
        unlawful processing of Customer Personal Data and against accidental
        loss or destruction of, or damage to, Customer Personal Data, which the
        Customer has reviewed and confirms are appropriate to the harm that
        might result from the unauthorised or unlawful processing or accidental
        loss, destruction or damage and the nature of the data to be protected,
        having regard to the state of technological development and the cost of
        implementing any measures;
        <br />
        (c) ensure that any personnel engaged and authorised by T Cards Direct
        to process Customer Personal Data have committed themselves to
        confidentiality or are under an appropriate statutory or common law
        obligation of confidentiality;
        <br />
        (d) assist the Customer insofar as this is possible (taking into account
        the nature of the processing and the information available to T Cards
        Direct), and at the Customer's cost and written request, in responding
        to any request from a data subject and in ensuring the Customer's
        compliance with its obligations under Applicable Data Protection Laws
        with respect to security, breach notifications, impact assessments and
        consultations with supervisory authorities or regulators;
        <br />
        (e) notify the Customer without undue delay on becoming aware of a
        personal data breach involving the Customer Personal Data;
        <br />
        (f) at the written direction of the Customer, delete or return Customer
        Personal Data and copies thereof to the Customer on termination of the
        contract unless T Cards Direct is required by Applicable Law to continue
        to process that Customer Personal Data. For the purposes of this clause
        1.8(f) Customer Personal Data shall be considered deleted where it is
        put beyond further use by T Cards Direct; and
        <br />
        (g) maintain records to demonstrate its compliance with this clause 1
        [and allow for reasonable audits by the Customer or the Customer's
        designated auditor, for this purpose, on reasonable written notice].
      </p>
      <p>
        5.9 The Customer hereby provides its prior, general authorisation for T
        Cards Direct to:
        <br />
        (a) appoint processors to process the Customer Personal Data, provided
        that T Cards Direct:
        <br />
        (i) shall ensure that the terms on which it appoints such processors
        comply with Applicable Data Protection Laws, and are consistent with the
        obligations imposed on T Cards Direct in this clause 5;
        <br />
        (ii) shall remain responsible for the acts and omission of any such
        processor as if they were the acts and omissions of T Cards Direct; and
        <br />
        (iii) shall inform the Customer of any intended changes concerning the
        addition or replacement of the processors, thereby giving the Customer
        the opportunity to object to such changes provided that if the Customer
        objects to the changes and cannot demonstrate, to T Cards Direct's
        reasonable satisfaction, that the objection is due to an actual or
        likely breach of Applicable Data Protection Law, the Customer shall
        indemnify T Cards Direct for any losses, damages, costs (including legal
        fees) and expenses suffered by T Cards Direct in accommodating the
        objection.
      </p>
      <p>
        5.10 Either party may, at any time on not less than 30 days' notice,
        revise this clause 5 by replacing it with any applicable controller to
        processor standard clauses or similar terms forming part of an
        applicable certification scheme (which shall apply when replaced by
        attachment to these terms).
      </p>
      <p>
        5.11 To the extent the parties act as joint-controllers in respect of
        personal data pursuant to these terms, the parties have agreed to
        allocate responsibility for each of their controller obligations under
        Applicable Data Protection Laws.
      </p>
      <p>
        5.12 Subject to clause 13.2 and 13.3 and notwithstanding any other terms
        of these terms], T Cards Direct's total aggregate liability in contract,
        tort (including negligence and breach of statutory duty howsoever
        arising), misrepresentation (whether innocent or negligent), restitution
        or otherwise, arising in connection with the performance or contemplated
        performance of these terms or any collateral contract insofar as it
        relates to the obligations set out in this clause 1, or Applicable Data
        Protection Laws shall be limited to the total Subscription Fees paid for
        the User Subscriptions during the six (6) months immediately preceding
        the date on which the claim arose.
      </p>
      <p>
        <b>Third party providers</b>
      </p>
      <p>
        The Customer acknowledges that the Services may enable or assist it to
        access the website content of, correspond with, and purchase products
        and services from, third parties via third-party websites and that it
        does so solely at its own risk. T Cards Direct makes no representation,
        warranty or commitment and shall have no liability or obligation
        whatsoever in relation to the content or use of, or correspondence with,
        any such third-party website, or any transactions completed, and any
        contract entered into by the Customer, with any such third party. Any
        contract entered into, and any transaction completed via any third-party
        website is between the Customer and the relevant third party, and not T
        Cards Direct. T Cards Direct recommends that the Customer refers to the
        third party's website terms and conditions and privacy policy prior to
        using the relevant third-party website. T Cards Direct does not endorse
        or approve any third-party website nor the content of any of the
        third-party website made available via the Services.
      </p>
      <p>
        <b>7. T Cards Direct obligations</b>
      </p>
      <p>
        7.1 T Cards Direct undertakes that the Services will be performed
        substantially in accordance with the Documentation and with reasonable
        skill and care.
      </p>
      <p>
        7.2 The undertaking at clause 7.1 shall not apply to the extent of any
        non-conformance which is caused by use of the Services contrary to T
        Cards Direct instructions, or modification or alteration of the Services
        by any party other than T Cards Direct or T Cards Direct duly authorised
        contractors or agents. If the Services do not conform with the foregoing
        undertaking, T Cards Direct will, at its expense, use all reasonable
        commercial endeavours to correct any such non-conformance promptly, or
        provide the Customer with an alternative means of accomplishing the
        desired performance. Such correction or substitution constitutes the
        Customer's sole and exclusive remedy for any breach of the undertaking
        set out in clause 7.1.
      </p>
      <p>
        7.3 T Cards Direct:
        <br />
        (a) does not warrant that:
        <br />
        (i) the Customer's use of the Services will be uninterrupted or
        error-free; or
        <br />
        (ii) that the Services, Documentation and/or the information obtained by
        the Customer through the Services will meet the Customer's requirements;
        or
        <br />
        (iii) the Software or the Services will be free from Viruses.
        <br />
        (b) is not responsible for any delays, delivery failures, or any other
        loss or damage resulting from the transfer of data over communications
        networks and facilities, including the internet, and the Customer
        acknowledges that the Services and Documentation may be subject to
        limitations, delays and other problems inherent in the use of such
        communications facilities.
      </p>
      <p>
        7.4 These terms shall not prevent T Cards Direct from entering into
        similar agreements with third parties, or from independently developing,
        using, selling or licensing documentation, products and/or services
        which are similar to those provided under these terms.
      </p>
      <p>
        7.5 T Cards Direct warrants that it has and will maintain all necessary
        licences, consents, and permissions necessary for the performance of its
        obligations under these terms.
      </p>
      <p>
        7.6 T Cards Direct shall not be responsible for any loss, destruction,
        alteration or disclosure of Customer Data caused by any third party
        (except those third parties sub-contracted by T Cards Direct to perform
        services related to Customer Data maintenance for which it shall remain
        fully liable.
      </p>
      <p>
        <b>8. Customer's obligations</b>
      </p>
      <p>
        8.1 The Customer shall:
        <br />
        (a) provide T Cards Direct with: (i) all necessary co-operation in
        relation to these terms; and (ii) all necessary access to such
        information as may be required by T Cards Direct; in order to provide
        the Services, including but not limited to Customer Data, security
        access information and configuration services;
        <br />
        (b) without affecting its other obligations under these terms, comply
        with all applicable laws and regulations with respect to its activities
        under these terms;
        <br />
        (c) carry out all other Customer responsibilities set out in these terms
        in a timely and efficient manner. In the event of any delays in the
        Customer's provision of such assistance as agreed by the parties, T
        Cards Direct may adjust any agreed timetable or delivery schedule as
        reasonably necessary;
        <br />
        (d) ensure that the Authorised Users use the Services and the
        Documentation in accordance with the terms and conditions of these
        terms, including (but not limited to) procuring the secure and
        safe-keeping of the Authorised User’s passwords and log-in details on
        the website for accessing the Services, and shall be responsible for any
        Authorised User's breach of these terms;
        <br />
        (e) obtain and shall maintain all necessary licences, consents, and
        permissions necessary for T Cards Direct, its contractors and agents to
        perform their obligations under these terms, including without
        limitation the Services;
        <br />
        (f) ensure that its network and systems comply with the relevant
        specifications provided by T Cards Direct from time to time; and
        <br />
        (g) be, to the extent permitted by law and except as otherwise expressly
        provided in these terms, solely responsible for procuring, maintaining
        and securing its network connections and telecommunications links from
        its systems to T Cards Direct's data centres, and all problems,
        conditions, delays, delivery failures and all other loss or damage
        arising from or relating to the Customer's network connections or
        telecommunications links or caused by the internet.
      </p>
      <p>
        8.2 The Customer shall own all right, title and interest in and to all
        of the Customer Data that is not personal data and shall have sole
        responsibility for the legality, reliability, integrity, accuracy and
        quality of all such Customer Data.
      </p>
      <p>
        <b>9. Charges and payment</b>
      </p>
      <p>
        9.1 The Customer shall pay the Subscription Fees to T Cards Direct for
        the User Subscriptions in accordance with this clause 9.
      </p>
      <p>
        9.2 The Customer shall on the Effective Date provide to T Cards Direct
        valid, up-to-date and complete details required to enable T Cards Direct
        to set up a payment mechanism (through Stripe or by way of a monthly
        standing order) and any other relevant valid, up-to-date and complete
        contact and billing details and, in providing such information, the
        Customer provides authorisation under these terms and conditions for T
        Cards Direct to deduct the agreed Subscription Fees from the Stripe
        account or bank account of the Customer:
        <br />
        (a) on the Effective Date for the Subscription Fees; and thereafter
        <br />
        (b) subject to Clause 14.1, on the same day (or next Business Day) of
        each calendar month for the Subscription Fees payable in advance in
        respect of that calendar month.
        <br />
        (c) where a Customer wishes to pay in advance, then T Cards Direct may,
        at its discretion, accept such payment and will invoice the Customer for
        6 or 12 months in advance.
      </p>
      <p>
        9.3 If T Cards Direct has not received payment within seven (7) days
        after the due date, and without prejudice to any other rights and
        remedies of T Cards Direct:
        <br />
        (a) T Cards Direct may, without liability to the Customer, disable the
        Customer’s password, account and access to all or part of the Services
        and T Cards Direct shall be under no obligation to provide any or all of
        the Services while the invoice(s) concerned remain unpaid; and
        <br />
        (b) interest shall accrue on such due amounts at an annual rate equal to
        3% over the then current base lending rate of T Cards Direct’s bankers
        in the UK at the date the relevant invoice was issued, commencing on the
        due date and continuing until fully paid, whether before or after
        judgment.
      </p>
      <p>
        9.4 All amounts and fees stated or referred to in these terms:
        <b />
        (a) shall be payable in pounds sterling;
        <b />
        (b) are exclusive of value added tax, which shall be added to T Cards
        Direct’s invoice(s) at the appropriate rate (if applicable).
      </p>
      <p>
        9.5 If, at any time whilst using the Services, the Customer exceeds the
        amount of disk storage space specified in the Documentation (or in the
        Subscription Confirmation), T Cards Direct shall charge the Customer,
        and the Customer shall pay, T Cards Direct’s then current excess data
        storage fees.
      </p>
      <p>
        9.6 T Cards Direct shall be entitled to increase the Subscription Fees,
        the fees payable in respect of the additional User Subscriptions
        purchased pursuant to Clause 3.3 and/or the excess storage fees payable
        pursuant to Clause 9.5, on renewal of the contract upon giving thirty
        (30) days’ prior written notice to the Customer before renewal date.
      </p>
      <p>
        <b>10. Proprietary rights</b>
      </p>
      <p>
        10.1 The Customer acknowledges and agrees that T Cards Direct and/or its
        licensors own all intellectual property rights in the Services and the
        Documentation. Except as expressly stated herein, these terms do not
        grant the Customer any rights to, under or in, any patents, copyright,
        database right, trade secrets, trade names, trademarks (whether
        registered or unregistered), or any other rights or licences in respect
        of the Services or the Documentation.
      </p>
      <p>
        10.2 T Cards Direct confirms that it has all the rights in relation to
        the Services and the Documentation that are necessary to grant all the
        rights it purports to grant under, and in accordance with, the terms of
        these terms.
      </p>
      <p>
        <b>11. Confidentiality</b>
      </p>
      <p>
        11.1 Each party may be given access to Confidential Information from the
        other party in order to perform its obligations under these terms. A
        party's Confidential Information shall not be deemed to include
        information that:
        <br />
        (a) is or becomes publicly known other than through any act or omission
        of the receiving party;
        <br />
        (b) was in the other party's lawful possession before the disclosure;
        <br />
        (c) is lawfully disclosed to the receiving party by a third party
        without restriction on disclosure; or
        <br />
        (d) is independently developed by the receiving party, which independent
        development can be shown by written evidence.
      </p>
      <p>
        11.2 Subject to clause 11.4, each party shall hold the other's
        Confidential Information in confidence and not make the other's
        Confidential Information available to any third party or use the other's
        Confidential Information for any purpose other than the implementation
        of these terms.
      </p>
      <p>
        11.3 Each party shall take all reasonable steps to ensure that the
        other's Confidential Information to which it has access is not disclosed
        or distributed by its employees or agents in violation of the terms of
        these terms.
      </p>
      <p>
        11.4 A party may disclose Confidential Information to the extent such
        Confidential Information is required to be disclosed by law, by any
        governmental or other regulatory authority or by a court or other
        authority of competent jurisdiction, provided that, to the extent it is
        legally permitted to do so, it gives the other party as much notice of
        such disclosure as possible and, where notice of disclosure is not
        prohibited and is given in accordance with this clause 11.4, it takes
        into account the reasonable requests of the other party in relation to
        the content of such disclosure.
      </p>
      <p>
        11.5 The Customer acknowledges that details of the Services, and the
        results of any performance tests of the Services, constitute T Cards
        Direct's Confidential Information.
      </p>
      <p>
        11.6 T Cards Direct acknowledges that the Customer Data is the
        Confidential Information of the Customer.
      </p>
      <p>
        11.7 The above provisions of this clause 11 shall survive termination of
        these terms, however arising.
      </p>
      <p>
        <b>12. Indemnity</b>
      </p>
      <p>
        12.1 The Customer shall defend, indemnify and hold harmless T Cards
        Direct against claims, actions, proceedings, losses, damages, expenses
        and costs (including without limitation court costs and reasonable legal
        fees) arising out of or in connection with the Customer's use of the
        Services and/or Documentation, provided that:
        <br />
        (a) the Customer is given prompt notice of any such claim;
        <br />
        (b) T Cards Direct provides reasonable co-operation to the Customer in
        the defence and settlement of such claim, at the Customer's expense; and
        <br />
        (c) the Customer is given sole authority to defend or settle the claim.
      </p>
      <p>
        12.2 T Cards Direct shall, subject to clause 12.5, defend the Customer,
        its officers, directors and employees against any claim that that the
        Customer’s use of the Services or Documentation in accordance with these
        terms infringes any (as from the Effective Date) any copyright,
        trademark, database right or right of confidentiality in the United
        Kingdom, and shall indemnify the Customer for any amounts awarded
        against the Customer in judgment or settlement of such claims, provided
        that:
        <br />
        (a) T Cards Direct is given prompt notice of any such claim;
        <br />
        (b) the Customer provides reasonable co-operation to T Cards Direct in
        the defence and settlement of such claim, at T Cards Direct’s expense;
        and
        <br />
        (c) T Cards Direct is given sole authority to defend or settle the
        claim.
      </p>
      <p>
        12.3 In the defence or settlement of any claim, T Cards Direct may
        procure the right for the Customer to continue using the Services,
        replace or modify the Services so that they become non-infringing or, if
        such remedies are not reasonably available, terminate these terms on two
        (2) Business Days’ notice to the Customer without any additional
        liability or obligation to pay liquidated damages or other additional
        costs to the Customer.
      </p>
      <p>
        12.4 In no event shall T Cards Direct, its employees, agents and
        sub-contractors be liable to the Customer to the extent that the alleged
        infringement is based on:
        <br />
        (a) a modification of the Services or Documentation by anyone other than
        T Cards Direct; or
        <br />
        (b) the Customer’s use of the Services or Documentation in a manner
        contrary to the instructions given to the Customer by T Cards Direct; or
        <br />
        (c) the Customer’s use of the Services or Documentation after notice of
        the alleged or actual infringement from T Cards Direct or any
        appropriate authority.
      </p>
      <p>
        12.5 The foregoing states the Customer’s sole and exclusive rights and
        remedies, and T Cards Direct’s (including T Cards Direct’s employees’,
        agents’ and sub-contractors’) entire obligations and liability, for
        infringement of any patent, copyright, trade mark, database right or
        right of confidentiality.
      </p>
      <p>
        <b>13. Limitation of liability</b>
      </p>
      <p>
        13.1 Except as expressly and specifically provided in these terms:
        <br />
        (a) the Customer assumes sole responsibility for results obtained from
        the use of the Services and the Documentation by the Customer, and for
        conclusions drawn from such use. T Cards Direct shall have no liability
        for any damage caused by errors or omissions in any information,
        instructions or scripts provided to T Cards Direct by the Customer in
        connection with the Services, or any actions taken by T Cards Direct at
        the Customer's direction;
        <br />
        (b) all warranties, representations, conditions and all other terms of
        any kind whatsoever implied by statute or common law are, to the fullest
        extent permitted by applicable law, excluded from these terms; and
        <br />
        (c) the Services and the Documentation are provided to the Customer on
        an "as is" basis.
      </p>
      <p>
        13.2 Nothing in these terms excludes the liability of T Cards Direct:
        <br />
        (a) for death or personal injury caused by T Cards Direct's negligence;
        or
        <br />
        (b) for fraud or fraudulent misrepresentation.
      </p>
      <p>
        13.3 Subject to clause 13.1 and clause 13.2:
        <br />
        (a) T Cards Direct shall not be liable whether in tort (including for
        negligence or breach of statutory duty), contract, misrepresentation,
        restitution or otherwise for any loss of profits, loss of business,
        depletion of goodwill and/or similar losses or loss or corruption of
        data or information, or pure economic loss, or for any special, indirect
        or consequential loss, costs, damages, charges or expenses however
        arising under these terms; and
        <br />
        (b) T Cards Direct's total aggregate liability in contract [(including
        in respect of the indemnity at clause 12.2)], tort (including negligence
        or breach of statutory duty), misrepresentation, restitution or
        otherwise, arising in connection with the performance or contemplated
        performance of these terms shall be limited to the total Subscription
        Fees paid for the User Subscriptions during the six (6) months
        immediately preceding the date on which the claim arose.
      </p>
      <p>
        13.4 Nothing in these terms excludes the liability of the Customer for
        any breach, infringement or misappropriation of T Cards Direct’s
        Intellectual Property Rights”.
      </p>
      <p>
        <b>14. Term and termination</b>
      </p>
      <p>
        14.1 These terms shall, unless otherwise terminated as provided in this
        clause 14, commence on the Effective Date and shall continue thereafter
        unless:
        <br />
        (a) either party notifies the other party of termination, in writing, on
        at least 30 days; or
        <br />
        (b) otherwise terminated in accordance with the provisions of these
        terms;
      </p>
      <p>
        14.2 Without affecting any other right or remedy available to it, either
        party may terminate these terms with immediate effect by giving written
        notice to the other party if:
        <br />
        (a) the other party commits a material breach of any other term of these
        terms and (if such breach is remediable) fails to remedy that breach
        within a period of 30 days after being notified in writing to do so;
        <br />
        (b) the other party suspends, or threatens to suspend, payment of its
        debts or is unable to pay its debts as they fall due or admits inability
        to pay its debts or is deemed unable to pay its debts within the meaning
        of section 123 of the Insolvency Act 1986;
        <br />
        (c) the other party commences negotiations with all or any class of its
        creditors with a view to rescheduling any of its debts, or makes a
        proposal for or enters into any compromise or arrangement with its
        creditors other than for the sole purpose of a scheme for a solvent
        amalgamation of that other party with one or more other companies or the
        solvent reconstruction of that other party;
        <br />
        (d) the other party applies to court for, or obtains, a moratorium under
        Part A1 of the Insolvency Act 1986;
        <br />
        (e) a petition is filed, a notice is given, a resolution is passed, or
        an order is made, for or in connection with the winding up of that other
        party other than for the sole purpose of a scheme for a solvent
        amalgamation of that other party with one or more other companies or the
        solvent reconstruction of that other party;
        <br />
        (f) an application is made to court, or an order is made, for the
        appointment of an administrator, or if a notice of intention to appoint
        an administrator is given or if an administrator is appointed, over the
        other party (being a company, partnership or limited liability
        partnership);
        <br />
        (g) the holder of a qualifying floating charge over the assets of that
        other party (being a company or limited liability partnership) has
        become entitled to appoint or has appointed an administrative receiver;
        <br />
        (h) a person becomes entitled to appoint a receiver over the assets of
        the other party or a receiver is appointed over the assets of the other
        party;
        <br />
        (i) a creditor or encumbrancer of the other party attaches or takes
        possession of, or a distress, execution, sequestration or other such
        process is levied or enforced on or sued against, the whole or any part
        of the other party's assets and such attachment or process is not
        discharged within [14] days;
        <br />
        (j) any event occurs, or proceeding is taken, with respect to the other
        party in any jurisdiction to which it is subject that has an effect
        equivalent or similar to any of the events mentioned in clause 14.2(b)
        to clause 14.2(j) (inclusive);
        <br />
        (k) the other party suspends or ceases, or threatens to suspend or
        cease, carrying on all or a substantial part of its business;
        <br />
        (l) the other party's financial position deteriorates so far as to
        reasonably justify the opinion that its ability to give effect to the
        terms of these terms is in jeopardy; and
        <br />
        (m) there is a change of control of the other party[ (within the meaning
        of section 1124 of the Corporation Tax Act 2010).
      </p>
      <p>
        14.3 On termination of these terms for any reason:
        <br />
        (a) all licences granted under these terms shall immediately terminate
        and the Customer shall immediately cease all use of the Services and/or
        the Documentation;
        <br />
        (b) each party shall return and make no further use of any equipment,
        property, Documentation and other items (and all copies of them)
        belonging to the other party;
        <br />
        (c) T Cards Direct may delete or otherwise dispose of any of the
        Customer Data in its possession unless T Cards Direct receives, no later
        than ten days after the effective date of the termination of these
        terms, a written request for the Customer data to be disabled. T Cards
        Direct shall use reasonable commercial endeavours to delete the Customer
        data within 14 days of its receipt of such a written request, provided
        that the Customer has, at that time, paid all fees and charges
        outstanding at and resulting from termination (whether or not due at the
        date of termination). The Customer shall pay all reasonable expenses
        incurred by T Cards Direct in disabling or deleting Customer Data; and
        <br />
        (d) any rights, remedies, obligations or liabilities of the parties that
        have accrued up to the date of termination, including the right to claim
        damages in respect of any breach of these terms which existed at or
        before the date of termination shall not be affected or prejudiced.
        <br />
        (e) In the event that a Customer ceases trading, all Customer Data shall
        be deleted within 14 days unless T Cards Direct receives a request in
        writing not to delete the Customer Data.
      </p>
      <p>
        <b>15. Force majeure</b>
      </p>
      <p>
        T Cards Direct shall have no liability to the Customer under these terms
        if it is prevented from or delayed in performing its obligations under
        these terms, or from carrying on its business, by acts, events,
        omissions or accidents beyond its reasonable control, including, without
        limitation, strikes, lock-outs or other industrial disputes (whether
        involving the workforce of T Cards Direct or any other party), failure
        of a utility service or transport or telecommunications network, act of
        God, war, riot, civil commotion, malicious damage, compliance with any
        law or governmental order, rule, regulation or direction, accident,
        breakdown of plant or machinery, fire, flood, storm, pandemic including
        Covid 19 or default of T Cards Directs or sub-contractors, provided that
        the Customer is notified of such an event and its expected duration.
      </p>
      <p>
        <b>16. Conflict</b>
      </p>
      <p>
        If there is an inconsistency between any of the provisions in the main
        body of these terms and the Schedules, the provisions in the main body
        of these terms shall prevail.
      </p>
      <p>
        <b>17. Variation</b>
      </p>
      <p>
        No variation of these terms shall be effective unless it is in writing
        and signed by the parties (or their authorised representatives).
      </p>
      <p>
        <b>18. Waiver</b>
      </p>
      <p>
        No failure or delay by a party to exercise any right or remedy provided
        under these terms or by law shall constitute a waiver of that or any
        other right or remedy, nor shall it prevent or restrict the further
        exercise of that or any other right or remedy. No single or partial
        exercise of such right or remedy shall prevent or restrict the further
        exercise of that or any other right or remedy.
      </p>
      <p>
        <b>19. Rights and remedies</b>
      </p>
      <p>
        Except as expressly provided in these terms, the rights and remedies
        provided under these terms are in addition to, and not exclusive of, any
        rights or remedies provided by law.
      </p>
      <p>
        <b>20. Severance</b>
      </p>
      <p>
        20.1 If any provision or part-provision of these terms is or becomes
        invalid, illegal or unenforceable, it shall be deemed deleted, but that
        shall not affect the validity and enforceability of the rest of these
        terms.
      </p>
      <p>
        20.2 If any provision or part-provision of these terms is deemed deleted
        under clause 20.1 the parties shall negotiate in good faith to agree a
        replacement provision that, to the greatest extent possible, achieves
        the intended commercial result of the original provision.
      </p>
      <p>
        <b>21. Entire agreement</b>
      </p>
      <p>
        21.1 These terms constitute the entire agreement between the parties and
        supersedes and extinguishes all previous agreements, promises,
        assurances, warranties, representations and understandings between them,
        whether written or oral, relating to its subject matter.
      </p>
      <p>
        21.2 Each party acknowledges that in entering into these terms it does
        not rely on, and shall have no remedies in respect of, any statement,
        representation, assurance or warranty (whether made innocently or
        negligently) that is not set out in these terms.
      </p>
      <p>
        21.3 Each party agrees that it shall have no claim for innocent or
        negligent misrepresentation or negligent misstatement based on any
        statement in these terms.
      </p>
      <p>
        21.4 Nothing in this clause shall limit or exclude any liability for
        fraud.
      </p>
      <p>
        <b>22. Assignment</b>
      </p>
      <p>
        22.1 The Customer shall not, without the prior written consent of T
        Cards Direct, assign, transfer, charge, sub-contract or deal in any
        other manner with all or any of its rights or obligations under these
        terms.
      </p>
      <p>
        22.2 T Cards Direct may at any time assign, transfer, charge,
        sub-contract or deal in any other manner with all or any of its rights
        or obligations under these terms.
      </p>
      <p>
        <b>23. No partnership or agency</b>
      </p>
      <p>
        Nothing in these terms is intended to or shall operate to create a
        partnership between the parties or authorise either party to act as
        agent for the other, and neither party shall have the authority to act
        in the name or on behalf of or otherwise to bind the other in any way
        (including, but not limited to, the making of any representation or
        warranty, the assumption of any obligation or liability and the exercise
        of any right or power).
      </p>
      <p>
        <b>24. Third party rights</b>
      </p>
      <p>
        These terms do not confer any rights on any person or party (other than
        the parties to these terms and, where applicable, their successors and
        permitted assigns) pursuant to the Contracts (Rights of Third Parties)
        Act 1999.
      </p>
      <p>
        <b>25. Notices</b>
      </p>
      <p>
        25.1 Any notice required to be given under these terms shall be in
        writing and shall be delivered by hand or sent by pre-paid first-class
        post or recorded delivery post to the other party at its address set out
        in these terms, or such other address as may have been notified by that
        party for such purposes or sent by fax to the other party's fax number
        as set out in these terms.
      </p>
      <p>
        25.2 A notice delivered by hand shall be deemed to have been received
        when delivered (or if delivery is not in business hours, at 9 am on the
        first business day following delivery). A correctly addressed notice
        sent by pre-paid first-class post or recorded delivery post shall be
        deemed to have been received at the time at which it would have been
        delivered in the normal course of post. A notice sent by fax shall be
        deemed to have been received at the time of transmission (as shown by
        the timed printout obtained by the sender).
      </p>
      <p>
        <b>26. Governing law</b>
      </p>
      <p>
        These terms and any dispute or claim arising out of or in connection
        with it or its subject matter or formation (including non-contractual
        disputes or claims) shall be governed by and construed in accordance
        with the law of England and Wales.
      </p>
      <p>
        <b>27. Jurisdiction</b>
      </p>
      <p>
        Each party irrevocably agrees that the courts of England and Wales shall
        have exclusive jurisdiction to settle any dispute or claim arising out
        of or in connection with these terms or its subject matter or formation
        (including non-contractual disputes or claims).
      </p>
      <p>
        <b>
          You agree that you have read, understood and agree to abide by these
          terms.
        </b>
      </p>
    </>
  );
};

const TermsOfUsePage = () => {
  return (
    <Layout>
      <SidebarLayout
        title='Terms of Use'
        nav={<LegalNav />}
        content={<Content />}
      />
    </Layout>
  );
};

export default TermsOfUsePage;
